<template>
  <section class="section">
    <div class="columns is-centered">
      <div class="column is-half opacity-box">
        <h1 class="title"> Log In </h1>
        <form @submit.prevent>
          <div class="field">
            <div class="control has-icons-left">
              <input class="input"
                v-model="username"
                type="username"
                placeholder="Username"
                required
              >
              <span class="icon is-small is-left">
                <font-awesome-icon icon="user"></font-awesome-icon>
              </span>
            </div>
          </div>
          <div class="field">
            <div class="control has-icons-left">
              <input class="input"
                v-model="password"
                type="password"
                placeholder="Password"
                required
              >
              <span class="icon is-small is-left">
                <font-awesome-icon icon="key"></font-awesome-icon>
              </span>
            </div>
          </div>
          <div class="field">
            <p class="control">
              <AsyncButton buttonClass="is-primary" @handle="handleLogin">
                Login
              </AsyncButton>
            </p>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import AsyncButton from '@/components/AsyncButton.vue';

export default {
  name: 'Login',
  components: {
    AsyncButton,
  },
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    async handleLogin() {
      const { username, password } = this;
      try {
        await this.$store.dispatch('auth/login', { username, password });
        this.$router.push({ name: 'Workouts' });
      } catch (error) {
        this.$store.dispatch('notification/setError', error.getMessage());
      }
    },
  },
};
</script>
